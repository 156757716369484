import React, { useEffect, useState } from "react";
import { Routes, Route, useNavigate, useLocation } from "react-router-dom";

import AdminLayout from "layouts/admin";
import AuthLayout from "layouts/auth";
// import Home from "Pages/Home/Home";

import AgencyLogin from "Auth/AgencyLogin/AgencyLogin";
import { selectUserInfo } from "../src/redux/userSlice";
import { useSelector } from "react-redux";
const App = () => {
  const Navigate = useNavigate();
  const userInfo = useSelector(selectUserInfo);
  const [isVisible, setIsVisible] = useState(true);
  const location = useLocation();
  console.log(location);
  console.log(isVisible);

  console.log(userInfo);

  useEffect(() => {
    const handleScroll = () => {
      if (window.scrollY > 300) {
        setIsVisible(false);
      } else {
        setIsVisible(true);
      }
    };

    window.addEventListener("scroll", handleScroll);
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  const isAdmin = userInfo?.data?.role === 1;
  return (
    <Routes>
      {isAdmin ? (
        <>
          <Route path="auth/*" element={<AuthLayout />} />
          <Route path="admin/*" element={<AdminLayout />} />
          {/* <Route path="/" element={<Navigate to="/admin" replace />} /> */}
          <Route path="/dashboard" element={<Navigate to="/admin" replace />} />
        </>
      ) : (
        <>
          {/* <Route path="/" element={<Home />} /> */}
          <Route path="/" element={<AgencyLogin />} />
        </>
      )}
    </Routes>
  );
};

export default App;
