import axiosInstance from "./base";

const userResources = {
  // login: async (loginData) => {
  //   try {
  //     const response = await axiosInstance.post("/login", loginData);
  //     return response.data;
  //   } catch (error) {
  //     throw error;
  //   }
  // },
  login: (data) => {
    return axiosInstance.post("/login", data);
  },
  register: (data) => {
    return axiosInstance.post("/register", data);
  },
  logout: () => {
    return axiosInstance.get("/user/logout");
  },
  };

export default userResources;
