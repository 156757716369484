import React, { useState } from "react";
import { FcGoogle } from "react-icons/fc";
import Checkbox from "components/checkbox";
import { useDispatch, useSelector } from "react-redux";
import { login, selectUserInfo, setUserInfo } from "../../redux/userSlice"; // Import your actions
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify"; // Ensure you have react-toastify installed
import userResources from "api/userResources";
import UserInputfield from "components/UserInputfield/UserInputfield";
import FixedPlugin from "components/fixedPlugin/FixedPlugin";
import katariatravels from "utils/images";

export default function AgencyLogin() {
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const userInfo = useSelector(selectUserInfo);
  console.log(userInfo, "userinfo");

  const submit = async (e) => {
    e.preventDefault(); // Prevent default form submission
    const data = {
      email: email,
      password: password,
    };
    try {
      const response = await userResources.login(data);
      console.log("Login response:", response); // Log API response for debugging

      if (response && response.data && response.data.data.role === 1) {
        dispatch(login(true));
        dispatch(setUserInfo(response.data));
        toast.success("Login successful!");
        navigate("/admin/default");
      } else {
        navigate("/");
        window.scrollTo(0, 0);
      }

      // Clear the input fields only after a successful login
      setEmail("");
      setPassword("");
    } catch (error) {
      console.error("Error logging in:", error); // Log error for debugging
      if (error.response && error.response.data.message) {
        toast.error(error.response.data.message); // Display API error
      } else {
        toast.error("Login failed. Please try again.");
      }
    }
  };

  return (
    <section>
      <div>
        <div className="relative float-right h-full min-h-screen w-full !bg-white dark:!bg-navy-900">
          <FixedPlugin />
          <main className={`mx-auto min-h-screen`}>
            <div className="relative flex">
              <div className="mx-auto flex min-h-full w-full flex-col justify-start pt-12 md:max-w-[75%]  lg:max-w-[1013px] lg:px-8 lg:pt-0 xl:min-h-[100vh] xl:max-w-[1383px] xl:px-0 xl:pl-[70px]">
                <div className="mb-auto flex flex-col pl-5 pr-5 md:pl-12 md:pr-0 lg:max-w-[48%] lg:pl-0 xl:max-w-full">
                  <div className="mb-16 mt-0 flex h-full w-full items-center justify-center px-2 sm:mt-16 md:mx-0 md:px-0 lg:mb-10 lg:items-center lg:justify-start">
                    <div className="mt-[0vh] w-full max-w-full flex-col items-center sm:mt-[10vh] md:pl-4 lg:pl-0 xl:max-w-[420px]">
                      <h4 className="mb-2.5 text-4xl font-bold text-navy-700 dark:text-[#bb8e25]">
                        Sign In
                      </h4>
                      <p className="mb-9 ml-1 text-base text-gray-600">
                        Enter your email and password to sign in!
                      </p>
                      <div className="mb-6 flex h-[50px] w-full items-center justify-center gap-2 rounded-xl bg-lightPrimary hover:cursor-pointer dark:bg-navy-800">
                        <div className="rounded-full text-xl">
                          <FcGoogle />
                        </div>
                        <h5 className="text-sm font-medium text-navy-700 dark:text-white">
                          Sign In with Google
                        </h5>
                      </div>
                      <div className="mb-6 flex items-center gap-3">
                        <div className="h-px w-full bg-gray-200 dark:bg-navy-700" />
                        <p className="text-base text-gray-600 dark:text-white">
                          {" "}
                          or{" "}
                        </p>
                        <div className="h-px w-full bg-gray-200 dark:bg-navy-700" />
                      </div>
                      {/* Email */}
                      <UserInputfield
                        variant="auth"
                        extra="mb-3"
                        label="Email*"
                        placeholder="mail@simmmple.com"
                        id="email"
                        type="text"
                        value={email} // Bind value to state
                        onChange={(e) => setEmail(e.target.value)} // Handle input change
                      />

                      {/* Password */}
                      <UserInputfield
                        variant="auth"
                        extra="mb-3"
                        label="Password*"
                        placeholder="Min. 8 characters"
                        id="password"
                        type="password"
                        value={password} // Bind the value to state
                        onChange={(e) => setPassword(e.target.value)} // Handle input change
                      />
                      {/* Checkbox */}
                      <div className="mb-4 flex items-center justify-between px-2">
                        <div className="flex items-center">
                          <Checkbox />
                          <p className="ml-2 text-sm font-medium text-navy-700 dark:text-white">
                            Keep me logged In
                          </p>
                        </div>
                        <a
                          className="text-sm font-medium text-brand-500 hover:text-brand-600 dark:text-white"
                          href=" "
                        >
                          Forgot Password?
                        </a>
                      </div>
                      <button
                        onClick={submit}
                        className="linear mt-2 w-full rounded-xl bg-brand-500 py-[12px] text-base font-medium text-white transition duration-200 hover:bg-brand-600 active:bg-brand-700 dark:bg-[#bb8e25] dark:text-white dark:hover:bg-[#bb8e25] dark:active:bg-[#bb8e25]"
                      >
                        Sign In
                      </button>
                    </div>
                  </div>
                  <div
                    className="absolute right-0 hidden h-full min-h-screen md:block lg:w-[49vw] 2xl:w-[50vw]"
                    style={{
                      backgroundImage: `url(${katariatravels})`,
                      backgroundSize: "cover",
                      backgroundPosition: "center",
                      backgroundRepeat: "no-repeat",
                    }}
                  ></div>
                </div>
                {/* <Footer /> */}
              </div>
            </div>
          </main>
        </div>
      </div>
    </section>
  );
}
