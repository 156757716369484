/* eslint-disable */

import { HiX } from "react-icons/hi";
import Links from "./components/Links";

import routes from "routes.js";
import { MdLock } from "react-icons/md";
import { toast } from "react-toastify";
import { logout } from "../../redux/userSlice";
import { useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux";

const Sidebar = ({ open, onClose }) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  return (
    <div
      className={`sm:none duration-175 linear fixed !z-50 flex min-h-full flex-col bg-white pb-10 shadow-2xl shadow-white/5 transition-all dark:!bg-navy-800 dark:text-white md:!z-50 lg:!z-50 xl:!z-0 ${
        open ? "translate-x-0" : "-translate-x-96"
      }`}
    >
      <span
        className="absolute right-4 top-4 block cursor-pointer xl:hidden"
        onClick={onClose}
      >
        <HiX />
      </span>

      <div className={`mx-[56px] mt-[50px] flex items-center`}>
        <div className="ml-1 mt-1 h-2.5 font-poppins text-[22px] font-bold uppercase text-navy-700 dark:text-white">
          Kataria <span class="font-medium">Travels</span>
        </div>
      </div>
      <div class="mb-7 mt-[58px] h-px bg-gray-300 dark:bg-white/30" />
      {/* Nav item */}
      <ul className="mb-auto pt-1">
        <Links routes={routes} />
        <li
          // onClick={() => {
          //   (async () => {
          //     try {
          //       const res = await userResources.logout();
          //       if (false) {
          //         Navigate("/");
          //         dispatch(logout());
          //       }
          //       toast.success(res.message);
          //     } catch (error) {
          //       toast.error("Logout failed:");
          //       // Handle Logout error
          //     }
          //   })();
          // }}
          onClick={() => {
            dispatch(logout());
            navigate("/");
          }}
          className="mx-8 flex cursor-pointer"
        >
          <MdLock className="mr-4 h-6 w-6" />
          Logout
        </li>
      </ul>

      {/* Free Kataria Card */}
      <div className="flex justify-center">{/* <SidebarCard /> */}</div>

      {/* Nav item end */}
    </div>
  );
};

export default Sidebar;
